import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Profile from "./pages/Profile/Profile";

import HomePageVitrine from "./pages/Home/Home";
import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";

import ResetPwd from "./pages/ResetPwd/ResetPwd";
import ForgotPasswordPage from "./pages/ResetPwd/ForgotPasswordPage";
import { UserProvider } from "./pages/Home/UserContext";
import PrimaryColorProvider from "./hooks/PrimaryColorProvider.jsx";

import DashboardUsers from "./pages/PagesUtilisateurs/DashboardUsers/DashboardUsers";

import Navbar2 from "./pages/Home/Navbar2/Navbar2";
import Footer from "./pages/Home/Footer";

import { NotificationProvider } from "./hooks/NotificationContext";
import { MessageProvider } from "./hooks/MessageContext.js";
import { ContactFormProvider } from "./hooks/ContactFormContext.js";

import ErrorPage from "./pages/ErrorPage/ErrorPage";

// PageVisiteurs

// Documents
import ReglementInterieur from "./pages/Documents/ReglementInterieur";
import ConditionsUtilisationPage from "./pages/rgpd/ConditionsUtilisationPage.jsx";
import PolitiqueConfidentialitePage from "./pages/rgpd/PolitiqueConfidentialitePage.jsx";
import FaqPage from "./pages/rgpd/FaqPage.jsx";
import MentionsLegalesPage from "./pages/rgpd/MentionsLegalesPage.jsx";

// PagesUtilisateurs
import InscriptionPage from "./pages/PagesUtilisateurs/InscriptionPage/InscriptionPage";
import InscriptionAdherent from "./pages/PagesUtilisateurs/InscriptionAdherent/InscriptionAdherent";
import AttestationPage from "./pages/PagesUtilisateurs/InscriptionAdherent/AttestationPage";
import FicheAdherent from "./pages/PagesUtilisateurs/FicheAdherent/FicheAdherent";
import TechniquesView from "./pages/PagesUtilisateurs/TechniquesView/TechniquesView.jsx";
import Messaging from "./pages/PagesUtilisateurs/Messaging/Messaging.js";
import LexiquePage from "./pages/PagesUtilisateurs/LexiquePage/LexiquePage.jsx";
import ListEvents from "./pages/PagesUtilisateurs/ListEvents/ListEvents";
import NewsPage from "./pages/PagesUtilisateurs/NewsPage/NewsPage";
import Boutique from "./pages/PagesUtilisateurs/Boutique/Boutique";
import GalleriesPhotos from "./pages/PagesUtilisateurs/GalleriesPhotos/GalleriesPhotos";
import AlbumGaleriePage from "./pages/PagesUtilisateurs/GalleriesPhotos/AlbumGaleriePage";
import UsersReglements from "./pages/PagesUtilisateurs/UsersReglements/UsersReglements";
import ChatPage from "./pages/PagesUtilisateurs/ChatPage/ChatPage";
import CheckoutForm from "./pages/PagesUtilisateurs/UsersReglements/CheckoutForm";
import PaymentSuccess from "./pages/PagesUtilisateurs/UsersReglements/PaymentSuccess";
import DiplomePage from "./pages/PagesUtilisateurs/FicheAdherent/DiplomePage";
import ArbitrageKarate from "./pages/PagesUtilisateurs/Arbitrage/ArbitrageKarate";
import WadoRyu from "./pages/PagesUtilisateurs/WadoRyu/WadoRyu";
import HistoriquePage from "./pages/PagesUtilisateurs/Historique/HistoriquePage";
import KataPage from "./pages/PagesUtilisateurs/Kata/KataPage";

// PagesEnseignants
import ListingAdherents from "./pages/PagesEnseignants/ListingAdherents/ListingAdherents";
import AttendancePage from "./pages/PagesEnseignants/AttendancePage/AttendancePage";
import EventsPage from "./pages/PagesEnseignants/EventsPage/EventsPage.jsx";
import SuiviCompetitions from "./pages/PagesEnseignants/Competitors/SuiviCompetitions.jsx";
import PassageCeintureGrid from "./pages/PagesEnseignants/PassageCeinture/PassageCeintureGrid.jsx";
import GradeCNPage from "./pages/PagesEnseignants/GradeCNPage/GradeCNPage.js";
import PalmaresPage from "./pages/PagesEnseignants/PalmaresPage/PalmaresPage.jsx";
import ToDoListPage from "./pages/PagesEnseignants/ToDoListPage/ToDoListPage.jsx";
import AttendanceTable from "./pages/PagesEnseignants/AttendanceTable/AttendanceTable.jsx";


// PagesAdministrateurs
import UserListing from "./pages/PagesAdministrateurs/UserListing/UserListing";
import Dashboard from "./pages/PagesAdministrateurs/Dashboard/Dashboard";
import DashboardCompta from "./pages/PagesAdministrateurs/DashboardCompta/DashboardCompta";
import DossierInscriptionPage from "./pages/PagesAdministrateurs/DossierInscriptionPage/DossierInscriptionPage";
import Achats from "./pages/PagesAdministrateurs/Achats/Achats";
import ReglementInscriptionPage from "./pages/PagesAdministrateurs/ReglementInscription/ReglementInscriptionPage";
import Coaching from "./pages/PagesAdministrateurs/Coaching/Coaching.jsx";
import SettingsPage from "./pages/PagesAdministrateurs/SettingsPage/SettingsPage.jsx";
import ListingAdherentsAdmin from "./pages/PagesAdministrateurs/ListingAdherentsAdmin/ListingAdherentsAdmin.jsx";
import ChatPageAdmin from "./pages/PagesAdministrateurs/ChatPageAdmin/ChatPageAdmin.jsx";
import MessageAdmin from "./pages/PagesAdministrateurs/MessageAdmin/MessageAdmin.jsx";
import HeuresEnseignement from "./pages/PagesAdministrateurs/HeuresEnseignement/HeuresEnseignement.jsx";
import InventairePage from "./pages/PagesAdministrateurs/InventairePage/InventairePage.jsx";
import DepensesRecettes from "./pages/PagesAdministrateurs/DepensesRecettes/DepensesRecettes.jsx";
import DetailsAdherent from "./pages/PagesAdministrateurs/DetailsAdherent/DetailsAdherent.jsx";
import ArticlesAdmin from "./pages/PagesAdministrateurs/ArticlesAdmin/ArticlesAdmin.jsx";

// ... importez d'autres pages si nécessaire
import "./App.css";
import "./bulma.min.css";

const stripePromise = loadStripe(
  "pk_test_51POgHVEr14Icaey6JeVMxJf9bAJS9AxDAGUTSDI80b01LVDorrxErNHC1HbTU3WRgJfPD3P40daULyEzeakVDBfk00fHPS7sVp"
);

const App = () => {
  // Gestion des states
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const getLocalStorageItem = (key) => {
      try {
        console.log("Tentative de lecture du localStorage pour la clé:", key);
        const item = localStorage.getItem(key);
        console.log("Valeur obtenue:", item);
        return item ? JSON.parse(item) : null;
      } catch (error) {
        console.error(
          "Erreur lors de la lecture du localStorage pour la clé",
          key,
          ":",
          error
        );
        return null;
      }
    };

    const storedUserInfo = getLocalStorageItem("userInfo");
    if (storedUserInfo) {
      setUserInfo(storedUserInfo);
    } else {
      console.error(
        "Les informations de l'utilisateur stockées sont introuvables ou corrompues."
      );
      // Actions supplémentaires ici, comme une redirection ou une récupération depuis un serveur
    }
  }, []);

  const RequireAuth = ({ children, roles }) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo")); // Assure-toi que userInfo est stocké dans localStorage
    const location = useLocation();

    if (!userInfo) {
      return <div>Chargement...</div>; // Ou un composant de chargement plus sophistiqué
    }

    if (roles && !roles.includes(userInfo.role_id)) {
      // Rôle non autorisé donc redirection vers une page d'erreur ou d'accueil
      return <Navigate to="/unauthorized" replace />;
    }

    return children;
  };

  // Pour l'exemple, nous allons simuler un utilisateur connecté
  const user = {
    id: 1,
    nom: "Doe",
    prenom: "John",
    email: "JohnDoe@gmail.com",
    photoURL: "path_to_photo.jpg", // Remplace par le chemin réel de la photo
    role_id: 1, // 1 = Administrateur
    userId: 1,
  };

  return (
    <UserProvider>
      <Router>
        <Elements stripe={stripePromise}>
          <MessageProvider>
            <NotificationProvider>
              <ContactFormProvider>
                <PrimaryColorProvider>
                  <div className="flex flex-col min-h-screen bg-base-200 dark:bg-gray-600 dark:text-gray-200">
                    <Navbar2
                      user={{
                        nom: userInfo?.nom ?? "Chargement...",
                        prenom: userInfo?.prenom ?? "",
                        role_id: userInfo?.role_id ?? 0,
                        photoURL: userInfo?.photoURL ?? "",
                        userId: userInfo?.id ?? 0,
                      }}
                    />
                    <Routes>
                      {/* Route pour la page d'accueil du site vitrine */}
                      <Route path="/" element={<HomePageVitrine />} />
                      <Route
                        path="/techniques-karate"
                        element={
                          <RequireAuth roles={[2, 3, 1, 4]}>
                            <TechniquesView />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/messages"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <Messaging />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/commandes-&-reglements"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <UsersReglements />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/tableau-de-bord"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <DashboardUsers />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/boutique"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <Boutique />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/chat"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <ChatPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/achats-boutique"
                        element={
                          <RequireAuth roles={[1]}>
                            <Achats />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/depenses-recettes"
                        element={
                          <RequireAuth roles={[1]}>
                            <DepensesRecettes />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/parametres"
                        element={
                          <RequireAuth roles={[1]}>
                            <SettingsPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/dossier-d-inscription"
                        element={
                          <RequireAuth roles={[2, 3, 1, 4]}>
                            <InscriptionAdherent />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/attestation"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <AttestationPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/diplome-grade"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <DiplomePage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/listing-utilisateurs"
                        element={
                          <RequireAuth roles={[1]}>
                            <UserListing />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/checkout"
                        element={
                          <RequireAuth roles={[1, 2, 3]}>
                            <CheckoutForm />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/reglements-inscriptions"
                        element={
                          <RequireAuth roles={[1, 2]}>
                            <ReglementInscriptionPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/dossiers-d-inscription"
                        element={
                          <RequireAuth roles={[1]}>
                            <DossierInscriptionPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/fiche-adherent"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <FicheAdherent />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/calendrier"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <ListEvents />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/actualites"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <NewsPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/lexique"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <LexiquePage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/arbitrage-karate"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <ArbitrageKarate />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/wado-ryu"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <WadoRyu />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/historique"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <HistoriquePage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/kata"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <KataPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/galerie-photos/album/:id"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <AlbumGaleriePage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/galerie-photos"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <GalleriesPhotos />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/listing-adherents"
                        element={
                          <RequireAuth roles={[3, 1]}>
                            <ListingAdherents />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/passage-ceinture"
                        element={
                          <RequireAuth roles={[3, 1]}>
                            <PassageCeintureGrid />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/grade-cn"
                        element={
                          <RequireAuth roles={[3, 1]}>
                            <GradeCNPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/suivi-competitions"
                        element={
                          <RequireAuth roles={[3, 1]}>
                            <SuiviCompetitions />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/palmares"
                        element={
                          <RequireAuth roles={[3, 1]}>
                            <PalmaresPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/todolist"
                        element={
                          <RequireAuth roles={[3, 1]}>
                            <ToDoListPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/coachings"
                        element={
                          <RequireAuth roles={[1]}>
                            <Coaching />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/heures-enseignement"
                        element={
                          <RequireAuth roles={[1]}>
                            <HeuresEnseignement />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/articles-admin"
                        element={
                          <RequireAuth roles={[1]}>
                            <ArticlesAdmin />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/inventaire"
                        element={
                          <RequireAuth roles={[1]}>
                            <InventairePage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/listing-adherents-admin"
                        element={
                          <RequireAuth roles={[1]}>
                            <ListingAdherentsAdmin />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/chat-page-admin"
                        element={
                          <RequireAuth roles={[1]}>
                            <ChatPageAdmin />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/message-admin"
                        element={
                          <RequireAuth roles={[1]}>
                            <MessageAdmin />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/evenements"
                        element={
                          <RequireAuth roles={[3, 1]}>
                            <EventsPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/appel-presence"
                        element={
                          <RequireAuth roles={[3, 1]}>
                            <AttendancePage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/tableau-appel"
                        element={
                          <RequireAuth roles={[3, 1]}>
                            <AttendanceTable />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/dashboard-adherents"
                        element={
                          <RequireAuth roles={[1]}>
                            <Dashboard />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/dashboard-comptabilite"
                        element={
                          <RequireAuth roles={[1]}>
                            <DashboardCompta />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/details-adherent"
                        element={
                          <RequireAuth roles={[1]}>
                            <DetailsAdherent />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/ajouter-inscription-adherent"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <InscriptionPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/profile"
                        element={
                          <RequireAuth roles={[2, 3, 1]}>
                            <Profile />
                          </RequireAuth>
                        }
                      />

                      <Route path="/login" element={<LoginPage />} />
                      <Route path="/register" element={<RegisterPage />} />
                      <Route
                        path="/reset-password/:token"
                        element={<ResetPwd />}
                      />
                      <Route
                        path="/forgot-password"
                        element={<ForgotPasswordPage />}
                      />
                      <Route path="/404" element={<ErrorPage />} />
                      <Route path="*" element={<ErrorPage />} />
                      <Route
                        path="/payment-success"
                        element={<PaymentSuccess />}
                      />
                      <Route
                        path="/reglement-interieur"
                        element={<ReglementInterieur />}
                      />
                      <Route
                        path="/conditions-utilisation"
                        element={<ConditionsUtilisationPage />}
                      />
                      <Route
                        path="/politique-confidentialite"
                        element={<PolitiqueConfidentialitePage />}
                      />
                      <Route path="/faq" element={<FaqPage />} />
                      <Route
                        path="/mentions-legales"
                        element={<MentionsLegalesPage />}
                      />
                    </Routes>
                    <Footer />
                  </div>
                </PrimaryColorProvider>
              </ContactFormProvider>
            </NotificationProvider>
          </MessageProvider>
        </Elements>
      </Router>
    </UserProvider>
  );
};

export default App;
