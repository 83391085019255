import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/attendance`;
const token = localStorage.getItem("token");

/// POST
// Fonction pour enregistrer l'appel
export const saveAttendance = async (attendances) => {
  try {
    console.log("Sending attendances:", attendances); // Log pour vérifier les données envoyées
    const response = await axios.post(
      `${apiUrl}/submitAttendance`,
      { attendances },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Response from server:", response.data); // Log pour voir la réponse du serveur
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'enregistrement de l'appel:", error);
    throw error;
  }
};

/// GET

// Fonction pour récupérer tous les appels
export const getAllAttendances = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getAllAttendances`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération de tous les appels:", error);
    throw error;
  }
};

// Fonction pour compter le nombre de présences et d'absences d'un adhérent, ainsi que le pourcentage de présence
export const countAttendance = async (adherentId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/calculateAssiduity/${adherentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors du comptage de l'appel:", error);
    throw error;
  }
};

// Fonction pour récupérer la liste des appels d'un adhérent
export const getAttendanceList = async (adherentId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/getAttendancesByAdherent/${adherentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération de la liste des appels:",
      error
    );
    throw error;
  }
};

/// DELETE
// Fonction pour supprimer tous les appels de tous les adhérents
export const deleteAllAttendances = async () => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteAll`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de tous les appels:", error);
    throw error;
  }
};
