import React, { useState, useEffect } from "react";
import Diaporama from "./Diaporama";
import About from "./About";
import FormContact from "./FormContact";
import GroupTable from "./GroupTable";
import AuthIcons from "./AuthIcons";
import ScrollIndicator from "./ScrollIndicator";
import Disciplines from "./Disciplines/Disciplines";
import HeaderCount from "./HeaderCount";
import UpcomingEvents from "./UpcomingEvents";
import LastNews from "./LastNews";
import useScrollToTop from "../../hooks/useScollToTop";
import LastGallerie from "./LastGallerie";
import "./Home.css";
import HeaderSlider from "./HeaderSlider";
import NosEquipes from "./NosEquipes";
import { useUser } from "./UserContext";
import GroupDistributionChart from "./../PagesAdministrateurs/Dashboard/GroupDistributionChart";
import PartenairesCarousel from "./PartenairesCarousel";
import { saveVisite } from "../../services/visitesServices";
import BeltCounts from "./BeltCounts";

function HomePageVitrine() {
  useScrollToTop();
  const { user } = useUser();
  useEffect(() => {
    const today = new Date().toISOString().slice(0, 10); // Obtenir la date au format AAAA-MM-JJ
    const lastVisitDate = localStorage.getItem("lastVisitDate");

    if (lastVisitDate !== today) {
      // Si la dernière visite enregistrée est différente d'aujourd'hui, on enregistre une nouvelle visite
      saveVisite();
      localStorage.setItem("lastVisitDate", today);
    }
  }, []);
  return (
    <div>
      {!user && <AuthIcons />}
      <ScrollIndicator />
      <div id="home">
        <HeaderSlider />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="events">
        <UpcomingEvents />
      </div>
      <div id="cours">
        <Diaporama />
      </div>
      <Disciplines />
      <GroupTable />
      <GroupDistributionChart />
      <NosEquipes />
      <HeaderCount />

      <div id="news">
        <LastNews />
      </div>
      <div id="galerie">
        <LastGallerie />
      </div>
      <div id="galerie">
        <BeltCounts />
      </div>
      <div id="partenaires">
        <PartenairesCarousel />
      </div>
      <div id="contact">
        <FormContact />
      </div>
    </div>
  );
}

export default HomePageVitrine;
